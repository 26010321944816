exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-sciences-user-acceptance-test-uat-for-clinical-trials-tsx": () => import("./../../../src/pages/life-sciences/user-acceptance-test-uat-for-clinical-trials.tsx" /* webpackChunkName: "component---src-pages-life-sciences-user-acceptance-test-uat-for-clinical-trials-tsx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/BlogListTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-case-study-list-template-tsx": () => import("./../../../src/templates/CaseStudyListTemplate.tsx" /* webpackChunkName: "component---src-templates-case-study-list-template-tsx" */),
  "component---src-templates-case-study-template-tsx": () => import("./../../../src/templates/CaseStudyTemplate.tsx" /* webpackChunkName: "component---src-templates-case-study-template-tsx" */),
  "component---src-templates-policy-template-tsx": () => import("./../../../src/templates/PolicyTemplate.tsx" /* webpackChunkName: "component---src-templates-policy-template-tsx" */),
  "component---src-templates-practice-template-tsx": () => import("./../../../src/templates/PracticeTemplate.tsx" /* webpackChunkName: "component---src-templates-practice-template-tsx" */)
}

